import Link from 'next/link';
import Undo from '../../../../tempIcons/Undo';

import { sortValues } from '../../../../components/Filters/Sort/Sort';
import ClickablePanel from '../../../../components/Toolkit/ClickablePanel/ClickablePanel';
import SmartLocalStorage from '../../../../helpers/storage/SmartLocalStorage';
import { sectionToSearchUrl } from '../../../../services/Search/sectionToSearchUrl';
import { SECTIONS } from '../../../../types';

import { useLastSearch } from './LastSearch.hook';
import { Container } from './LastSearch.styled';
import { LastSearchType } from './LastSearch.types';
import { createSearchUrlSeoService } from '../../../../services/Url/SearchSEOParams';

export const LastSearch = ({ lastSearch }: { lastSearch: LastSearchType }) => {
  const { generateParams, getFormattedSection } = useLastSearch({
    searchQuery: lastSearch.searchQueryUpdated,
  });

  const {
    searchQueryUpdated: { section },
    titleSearch,
    numberOfFilters,
  } = lastSearch;

  const lastSearchedRoute = sectionToSearchUrl(section);

  const sortDisplayName = sortValues.find(
    (sort) => sort.value === lastSearch.searchQueryUpdated.sort,
  )?.displayName;

  const title = `Last search: ${titleSearch ?? 'Custom Area'}`;

  const sectionDisplayName = getFormattedSection(section as SECTIONS);

  const handleClick = () => {
    SmartLocalStorage.set('source', 'lastSearch');
  };
  return (
    lastSearchedRoute && (
      <Container>
        <Link
          href={createSearchUrlSeoService().toUrlStateless(
            lastSearchedRoute,
            generateParams(lastSearch),
          )}
          passHref
          legacyBehavior
        >
          <ClickablePanel
            border={true}
            spaceBetween={'medium'}
            IconComponent={<Undo alt="Last search icon" id="undo-icon" />}
            content={{
              heading: title,
              subHeading: `${sectionDisplayName} - ${sortDisplayName} ${numberOfFilters}`,
            }}
            dataTracking="click_last_search"
            id="last-search-clickable-panel"
            // The <Link> component from next/link with legacyBehavior prop set to true means this
            // ClickablePanel will be cloned by Link and an href will be passed automatically.
            href=""
            opensInSameTab={true}
            onClick={handleClick}
          />
        </Link>
      </Container>
    )
  );
};
