import Link from 'next/link';
import React from 'react';

import { quickLinksInfo, QuickLinksProps } from './QuickLinks.config';
import * as S from './QuickLinks.styled';

import { NewButtonTypes, NewButtonSizes, CategoryName } from '../../../types';
import { NewButton } from '../../Toolkit/NewButton/NewButton';

const QuickLinks: React.FC<QuickLinksProps> = ({
  category,
  activeSection,
  title,
}) => {
  const getLinksToUse = (category: CategoryName, activeSection?: string) => {
    if (category === 'COMMERCIAL' && activeSection === 'To Lease') {
      return quickLinksInfo.COMMERCIAL_RENT;
    } else if (category === 'COMMERCIAL' && activeSection === 'For Sale') {
      return quickLinksInfo.COMMERCIAL_BUY;
    }
    return quickLinksInfo[category] || [];
  };

  const linksToUse = getLinksToUse(category, activeSection);

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.List>
        {linksToUse.map((link, index) => (
          <S.Item key={index}>
            <Link href={link.route} passHref legacyBehavior>
              <NewButton
                data-testid={`quick-link-${index}`}
                iconName={link.icon ? 'SWITCH' : ''}
                buttonText={link.linkText}
                buttonSize={NewButtonSizes.LARGE}
                buttonType={NewButtonTypes.TERTIARY}
                fullWidth={true}
                href={link.route}
              />
            </Link>
          </S.Item>
        ))}
      </S.List>
    </S.Wrapper>
  );
};

export { QuickLinks };
