import ClickablePanel from '../../../components/Toolkit/ClickablePanel/ClickablePanel';
import {
  NewButtonSizes,
  NewButtonTypes,
  SemanticButtonTypes,
} from '../../../components/Toolkit/NewButton/NewButton';
import { Tabs } from '../../../components/Toolkit/Tabs/Tabs';

import {
  Container,
  StyledH3,
  Tab,
  TabContent,
  TabsWrapper,
} from './TabsPanels.styled';
import {
  TabsPanelsListItem,
  TabsPanelsProps,
  TabsProps,
} from './TabsPanels.types';
import { ImageWrapper } from '@dist-property-frontend/toolkit';
import { useCheckIsMobile } from '@dist-property-frontend/services';

export const TabsPanels = ({
  activeTab,
  tabsPanels,
  title,
}: TabsPanelsProps) => {
  const { isMobileState } = useCheckIsMobile();

  // set margin spacing based on mobile state
  const setMarginSpacing = () => {
    if (isMobileState) {
      return '0 16px 0 0';
    } else {
      return '0 24px 0 0';
    }
  };

  return (
    <>
      {title && <StyledH3 data-testid="tabs-panel-title">{title}</StyledH3>}
      <Tabs activeTab={activeTab}>
        {({ active, setActive }: TabsProps) => (
          <Container data-testid={`tabs-panels-${title}`}>
            <TabsWrapper>
              {tabsPanels.map((item, index) => (
                <Tab
                  key={index}
                  isActive={active === index}
                  type={SemanticButtonTypes.button}
                  onClick={() => setActive(index)}
                  data-testid={`tabs-panels-${index}`}
                  buttonText={item.section}
                  buttonType={NewButtonTypes.GHOST}
                  buttonSize={NewButtonSizes.LARGE}
                />
              ))}
            </TabsWrapper>

            <div>
              {tabsPanels.map((item, index) => (
                <TabContent
                  key={index}
                  isActive={active === index}
                  totalLinks={item.content.list.length}
                  data-testid="tabs-panels-list"
                >
                  {item.content.list.map(
                    ({
                      cardTitle,
                      id,
                      cardText,
                      url,
                      image,
                      opensInSameTab,
                    }: TabsPanelsListItem) => (
                      <li key={id}>
                        <ClickablePanel
                          border={true}
                          spaceBetween={'small'}
                          IconComponent={
                            <ImageWrapper
                              src={image}
                              alt={cardTitle}
                              height={64}
                              width={80}
                              style={{ margin: setMarginSpacing() }}
                              unoptimized
                            />
                          }
                          content={{
                            heading: cardTitle,
                            subHeading: cardText,
                          }}
                          dataTracking={id}
                          id={id}
                          href={url}
                          opensInSameTab={opensInSameTab}
                        />
                      </li>
                    ),
                  )}
                </TabContent>
              ))}
            </div>
          </Container>
        )}
      </Tabs>
    </>
  );
};
