import Link from 'next/link';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  PopularAreasListItem,
  PopularAreasType,
} from '../../../config/popularAreas';

import {
  NewButtonSizes,
  NewButtonTypes,
  SemanticButtonTypes,
} from '../../../types';
import { NewButton } from '../../Toolkit/NewButton/NewButton';
import { Tabs } from '../../Toolkit/Tabs/Tabs';

type StyledProps = {
  isActive: boolean;
};

type PopularAreasProps = {
  activeTab: number;
  currentSectionName: string;
  popularAreas: PopularAreasType[];
  searchPath: string;
};

type TabsProps = {
  active: number;
  setActive: (tab: number) => void;
};

const Wrapper = styled.div`
  overflow-x: auto;
`;

const Container = styled.div`
  border-bottom: 1px solid #dddddd;
  min-width: 800px;
  @media only screen and (min-width: 640px) {
    min-width: 1024px;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 16px;
  @media only screen and (min-width: 1040px) {
    width: 100%;
    padding: 0;
  }
`;

const Tab = styled(NewButton)<StyledProps>`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 0;
  background: none;
  outline: 0;
  color: #333333;
  ${({ isActive }) =>
    isActive ? 'border-bottom: 3px solid #3e3e3e; font-weight: 600;' : ''}

  &:active {
    border-bottom: 3px solid #3e3e3e;
    font-weight: 600;
  }
`;

const TabContent = styled.ul<{ isActive: boolean; totalLinks: number }>`
  padding: 16px 16px;
  @media only screen and (min-width: 1040px) {
    padding: 16px 0;
  }
  margin: 0 auto;
  max-width: 1040px;
  min-width: 800px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-flow: column wrap;
  height: ${({ totalLinks }) => `calc(${totalLinks} * 13px);`};
`;

const ListItem = styled.li`
  width: 25%;
  margin: 10px 0;
  padding: 2px 0;
`;

const AreaTitle = styled.span`
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #333333;
  }
`;

const StyledH4 = styled.h4`
  padding: 0 16px;
  max-width: 1040px;
  font-size: 16px;
  line-height: 24px;
  @media only screen and (min-width: 1040px) {
    font-size: 18px;
    padding: 0;
  }

  font-weight: 400;
  margin: 0 auto 32px;
`;

const StyledSearchAllLink = styled.a`
  font-weight: 600;
  text-decoration: underline;
  color: #333333;
  :visited {
    color: #333333;
  }
  :hover {
    cursor: pointer;
  }
`;

export const PopularAreas: FC<PopularAreasProps> = (
  props: PopularAreasProps,
) => {
  const { currentSectionName, popularAreas, activeTab, searchPath } = props;
  return (
    <>
      <StyledH4>
        Search{' '}
        <Link href={`${searchPath}/ireland`} passHref legacyBehavior>
          <StyledSearchAllLink data-testid="homepage-popular-area-seo-link">
            all of Ireland,{' '}
          </StyledSearchAllLink>
        </Link>
        <Link href={`/recent/ireland`} passHref legacyBehavior>
          <StyledSearchAllLink data-testid="homepage-recent-section-popular-area-seo-link">
            recently added
          </StyledSearchAllLink>
        </Link>
        , or search by popular areas
      </StyledH4>
      <Tabs activeTab={activeTab}>
        {({ active, setActive }: TabsProps) => (
          <Wrapper data-testid="popular-areas">
            <Container>
              <TabsWrapper>
                {popularAreas.map((item, index) => (
                  <Tab
                    key={index}
                    isActive={active === index}
                    type={SemanticButtonTypes.button}
                    onClick={() => setActive(index)}
                    data-testid={`popular-areas-tab-${index}`}
                    buttonText={item.title}
                    buttonType={NewButtonTypes.GHOST}
                    buttonSize={NewButtonSizes.LARGE}
                  />
                ))}
              </TabsWrapper>
            </Container>
            <div>
              {popularAreas.map((item, index) => (
                <TabContent
                  key={`popular-areas-tabs-${index}`}
                  isActive={active === index}
                  totalLinks={item.content.list.length}
                  data-testid={`popular-areas-content-${index}`}
                >
                  {item.content.list.map(
                    ({ title, url }: PopularAreasListItem, index: number) => (
                      <ListItem key={`popular-areas-${index}`}>
                        <Link href={url[currentSectionName]}>
                          <AreaTitle key={index}>{title}</AreaTitle>
                        </Link>
                      </ListItem>
                    ),
                  )}
                </TabContent>
              ))}
            </div>
          </Wrapper>
        )}
      </Tabs>
    </>
  );
};
