import styled from 'styled-components';

export const Container = styled.div<{
  background: boolean;
  border: boolean;
  borderRadius: boolean;
}>`
  position: relative;
  display: ${({ theme }) => (theme.panelTheme?.horizontal ? 'flex' : 'block')};
  padding: ${({ theme }) => theme.panelTheme?.panelPadding};
  width: 100%;

  border-color: ${({ border, theme }) =>
    border ? theme.panelTheme?.border?.borderColor : 'none'};
  border-style: ${({ border, theme }) =>
    border ? theme.panelTheme?.border?.borderStyle : 'none'};
  border-width: ${({ border, theme }) =>
    border ? theme.panelTheme?.border?.borderWidth : 'none'};
  border-radius: ${({ border, borderRadius, theme }) => {
    if (borderRadius) {
      return theme.border.radius.STANDARD;
    }
    return border ? theme.panelTheme?.border?.borderWidth : 'none';
  }};

  background-color: ${({ background, theme }) =>
    background ? `${theme.color.PRIMARY_LIGHTEST}` : `${theme.color.WHITE}`};

  max-width: 680px; // This is to match the SRP cards
  margin: auto;
`;

export const Title = styled.span<{ image: boolean }>`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  inline-size: ${({ theme, image }) => {
    if (theme.panelTheme?.horizontal) {
      return 'calc(100% - 10px)';
    }
    return image ? null : 'auto';
  }};
  font-weight: ${({ theme }) => theme.panelTheme?.title.fontWeight};
  ${({ theme }) => theme.panelTheme?.title.fontSize};
  margin-right: ${({ theme, image }) => (image ? '0' : theme.spacing.S8)};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.panelTheme?.subtitle.fontSize};
  margin-bottom: ${({ theme }) => theme.panelTheme?.subtitle.bottomMargin};
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.panelTheme?.closeIconMargin};
  top: ${({ theme }) => theme.panelTheme?.closeIconMargin};
  cursor: pointer;
  svg {
    width: ${({ theme }) => theme.spacing.M16};
    height: ${({ theme }) => theme.spacing.M16};
    color: ${({ theme }) => theme.color.GREY_DARK};
  }
`;

export const ImageWrapper = styled.div<{ imgPosition: 'start' | 'center' }>`
  @media only screen and (min-width: 1004px) {
    display: flex;
    align-items: ${({ imgPosition }) => `${imgPosition}`};
  }
`;

export const Image = styled.img`
  margin-right: ${({ theme }) =>
    theme.panelTheme?.horizontal ? theme.spacing.M24 : '0px'};
  max-width: 138px;
  max-height: 96px;
  margin-bottom: ${({ theme }) => theme.panelTheme?.imageBottomMargin};
`;

export const SecondImage = styled.img`
  margin-left: ${({ theme }) => theme.spacing.M24};
  max-width: 160px;
  max-height: 20px;
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => theme.fontSize.S14};
  color: ${({ theme }) => theme.color.PRIMARY};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};

  a {
    color: ${({ theme }) => theme.color.PRIMARY};
  }
`;

export const TitleIconWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;
