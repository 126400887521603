import React, { useState } from 'react';

import styled from 'styled-components';

import { PAGE_BREAK_POINT_640PX } from '../../../types/breakpoints';
import {
  Badge,
  BadgeColor,
  BadgeSize,
  BadgeStyle,
} from '@dist-property-frontend/toolkit';

export type SectionType = {
  url: string;
  name: string;
  isExternalLink?: boolean;
  hasNewTabLabel?: boolean;
};

export type SectionPickerProps = {
  onChange: (section: SectionType) => void;
  sections: SectionType[];
  activeSection?: number;
};

export type StyledSectionProp = {
  isSelected: boolean;
  onClick: (section: SectionType) => void;
  hasNewTabLabel?: boolean;
};

const SectionWrapper = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  ${({ theme }) => theme.fontSize.S14};
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_640PX}) {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
    ${({ theme }) => theme.fontSize.T18};
  }

  &:focus {
    outline: 0;
  }
`;

const Section = styled.li<StyledSectionProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  text-align: center;
  text-decoration: none;
  padding: ${({ theme, hasNewTabLabel }) =>
    hasNewTabLabel
      ? `${theme.spacing.S2} ${theme.spacing.S4} ${theme.spacing.S8} ${theme.spacing.S8}`
      : `${theme.spacing.S2} ${theme.spacing.S8} ${theme.spacing.S8} ${theme.spacing.S8}`};
  margin: 0 0px 10px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.PRIMARY};

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: ${({ theme }) => theme.color.PRIMARY};
      height: 3px;
      width: 70%;
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `color: ${theme.color.PRIMARY};
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: ${theme.color.PRIMARY};
      height: 3px;
      width: 70%;
    }`};

  @media only screen and (min-width: 640px) {
    margin: ${({ hasNewTabLabel }) =>
      hasNewTabLabel ? `0 0 10px 8px` : `0 8px 10px`};
    ${({ theme, hasNewTabLabel }) =>
      hasNewTabLabel &&
      `padding: ${theme.spacing.S2} ${theme.spacing.S8} ${theme.spacing.S8} ${theme.spacing.S8}`};
  }
`;

const SectionTabLabelContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.S2};

  @media only screen and (min-width: 640px) {
    margin-top: ${({ theme }) => theme.spacing.S4};
  }
`;

const SectionLink = Section.withComponent('a');

export const SectionPicker = ({
  sections,
  activeSection,
  onChange,
}: SectionPickerProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    activeSection || 0,
  );

  const onClick = (section: SectionType, index: number) => {
    setSelectedIndex(index);
    onChange(section);
  };

  return (
    <SectionWrapper>
      {sections.map((section, index) => (
        <React.Fragment key={section.name}>
          {section.isExternalLink ? (
            <SectionLink
              href={section.url}
              isSelected={index === selectedIndex}
              onClick={() => onClick(section, index)}
              data-testid={`section-link-${section.name}`}
            >
              {section.name}
            </SectionLink>
          ) : (
            <Section
              role="menuitem"
              tabIndex={0}
              isSelected={index === selectedIndex}
              onClick={() => onClick(section, index)}
              onKeyDown={() => onClick(section, index)}
              data-testid={`section-link-${section.name}`}
              hasNewTabLabel={section.hasNewTabLabel}
            >
              {section.name}
            </Section>
          )}
          {section?.hasNewTabLabel && (
            <SectionTabLabelContainer>
              <Badge
                badgeColor={BadgeColor.GREEN}
                badgeSize={BadgeSize.SMALL}
                badgeStyle={BadgeStyle.FILLED_LIGHT}
                data-testid={`badge-${section.name}`}
              >
                NEW
              </Badge>
            </SectionTabLabelContainer>
          )}
        </React.Fragment>
      ))}
    </SectionWrapper>
  );
};
