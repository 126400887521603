import React, { RefObject, useCallback, useRef, useState } from 'react';

import { AutocompleteAPI } from '../../../api';
import { SectionPicker } from '../../../components/Toolkit/SectionPicker/SectionPicker';
import { AreaTag, SECTIONS } from '../../../types';

import { LastSearch } from './LastSearch/LastSearch';
import { LastSearchType } from './LastSearch/LastSearch.types';
import * as Styled from './SectionAutocomplete.styled';
import { AutocompleteConnectionHelper } from '../../MultiInputTextSelect/AutocompleteConnectionHelper';
import { HomepageSearchBox } from '../../MultiInputTextSelect/HomepageSearchBox';
import ErrorBoundary from '../../../helpers/components/ErrorBoundary';
import { Text, TextSize } from '@dist-property-frontend/toolkit';

type Section = {
  name: string;
  url: string;
  pageRoute?: string;
  isExternalLink?: boolean;
  section: SECTIONS;
};

interface SectionedAutoCompleteProps {
  sections: Section[];
  handleSectionChanged: (
    urlPath: string,
    pageRoute: string,
    isExternalLink?: boolean,
  ) => void;
  search: (selectedItems: AreaTag[]) => void;
  currentSection: Section;
  currentPath: string;
  placeholderText: string;
  disablePropertyCount: boolean;
  lastSearch?: LastSearchType | null;
}

export const SectionedAutocomplete = (props: SectionedAutoCompleteProps) => {
  const { lastSearch } = props;
  const [selectedSection, setSelectedSection] = useState<Section>(
    props.currentSection,
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const searchBoxRef: RefObject<HTMLElement | null | undefined> = useRef();

  const [height, setHeight] = useState(0);

  const sectionAutocompleteRef = useCallback((node: any) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().top);
    }
  }, []);

  const handleSectionChange = (section: Section) => {
    const matchingSection = props.sections.find(
      (item) => item.url === section.url,
    );
    props.handleSectionChanged(
      section.url,
      matchingSection?.pageRoute ? matchingSection.pageRoute : '',
      matchingSection?.isExternalLink,
    );

    const activeSection = matchingSection ? matchingSection : props.sections[0];
    setSelectedSection(activeSection);

    if (!activeSection.isExternalLink) {
      setIsFocused(true);
    }
  };

  const search = (selectedItems: AreaTag[]) => {
    props.search(selectedItems);
  };

  const mobileShift = () => {
    if (screen.width <= 525 && height) {
      document.body.style.height = '1500px';

      window.scrollTo({
        top: height,
        behavior: 'smooth',
      });
    }
  };

  const mobileUnShift = () => {
    document.body.style.height = '';
  };

  const onFocus = (event: React.FocusEvent) => {
    if (event?.target?.getAttribute('aria-label') === 'search') {
      return;
    }
    mobileShift();
    setIsFocused(true);
  };

  const onBlur = () => {
    mobileUnShift();
    setIsFocused(false);
  };

  const { sections, currentPath, placeholderText, disablePropertyCount } =
    props;

  const activeSection = sections.indexOf(selectedSection);

  return (
    <Styled.StyledDiv
      ref={sectionAutocompleteRef}
      data-testid="homepage-sectioned-autocomplete"
    >
      <SectionPicker
        onChange={handleSectionChange}
        activeSection={activeSection}
        sections={sections}
      />

      <AutocompleteConnectionHelper
        path={currentPath}
        apiCall={AutocompleteAPI.suggestions}
      >
        {({ getAutocompleteProps }: any) => (
          <HomepageSearchBox
            {...getAutocompleteProps({
              ref: searchBoxRef,
              id: 'search-box',
              search: search,
              onFocus: onFocus,
              onBlur: onBlur,
              selectedSection: selectedSection.section,
              isFocused: isFocused,
              placeholderText,
              disablePropertyCount,
            })}
            selectedSectionURL={selectedSection.url}
          />
        )}
      </AutocompleteConnectionHelper>

      {!isFocused && lastSearch && (
        <ErrorBoundary
          renderFallback={() => (
            <Text as={'p'} size={TextSize.S12} style={{ paddingTop: '10px' }}>
              Last Search error
            </Text>
          )}
        >
          <LastSearch lastSearch={lastSearch} />
        </ErrorBoundary>
      )}
    </Styled.StyledDiv>
  );
};
